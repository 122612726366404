import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import {
  ArrowCircleLeft,
  ArrowCircleRight,
  Award,
  Calendar,
  DocumentText1,
  Eye,
  EyeSlash,
  Global,
  Import,
  InfoCircle,
  Lock1,
  MouseCircle,
  Notepad2,
  Paperclip2,
  Verify,
} from "iconsax-react";

import * as Yup from "yup";
import MainCard from "../../Component/MainCard";
import SingleFileUpload from "../../Component/third_party/SingleFileUpload";
import moment from "moment";
import { useGetAllDomains } from "../../Hooks/links/AdvanceLinks/useGetAllAdvanceData";
import {
  GetBulkIdData,
  useAddBulk,
  useEditBulk,
} from "../../Hooks/BulkLinks/usebulk";
import { fileDownload, notificationToast } from "../../Helpers/common";
import { notificationMessageFunction } from "../../Constants/notificationConst";
import { useDownloadAliasDemoTagsFile } from "../../Hooks/BulkLinks/useBulkLink";
import { errorConst as ERR_CONSTS } from "../../Constants/errorSuccessConstants.js";
import { useLocation, useNavigate } from "react-router";
import editImg from "../../assets/images/editBulk.png";
import { useAccount } from "../../Hooks/Other/useAccount.js";
import "moment-timezone";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { ThemeMode } from "../../config.js";

const BulkLinksForm = ({
  onOffList,
  displayList,
  refetchData,
  setDisplayListCall,
  setDisplayList,
  edit,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [domain, setDomain] = useState("");
  const [selectedValue, setSelectedValue] = useState("links");
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: domainList,
    refetch: domainRefetch,
    isFetching,
  } = useGetAllDomains();
  const { refetch: downloadTagsCSV, data: downloadTagsData } =
    useDownloadAliasDemoTagsFile();
  const {
    mutate,
    error,
    data,
    isLoading: addBulkLoading,
    isFetching: addBulkFetching,
  } = useAddBulk();
  const { data: dateData } = useAccount();
  const { data: editData, refetch: editIdRefetch } = GetBulkIdData(
    location?.state?.id
  );
  const {
    mutate: editMutate,
    error: editError,
    data: edited,
    isLoading: EditIsLoading,
  } = useEditBulk();

  useEffect(() => {
    if (editData) {
      formik.setFieldValue("domain_id", editData?.domain_id || domain);
      setDomain(editData?.domain_id);
    } else {
      if (domainList?.find((i) => i?.set_primary)) {
        formik.setFieldValue(
          "domain_id",
          domainList?.find((i) => i?.set_primary)?.domain_id
        );
      }
    }
  }, [domainList, editData]);

  useEffect(() => {
    domainRefetch();
    edit && editIdRefetch();
  }, []);
  useEffect(() => {
    if (edit && location.state.id) {
      domainRefetch();
      editIdRefetch();
    }
  }, [location]);

  useEffect(() => {
    if (editData) {
      setData();
      setLoader(false);
    }
  }, [editData]);

  const setData = () => {
    formik.setFieldValue("bunch_name", editData?.bunch_name);
    formik.setFieldValue("domain_id", editData?.domain_id || domain);
    setDomain(editData?.domain_id);
    formik.setFieldValue("password", editData?.password || "");
    formik.setFieldValue("expire_on", editData?.expire_on || "");
    formik.setFieldValue("status", editData?.status);
    formik.setFieldValue("expiration_link", editData?.expiration_link || "");
    formik.setFieldValue("clicks", editData?.clicks || "");
    formik.setFieldValue("tags", editData?.tags || []);
    formik.setFieldValue("note", editData?.note || "");
  };

  let regexQuery =
    "^(mailto:)?[a-z0-9%+_.-]+@[a-z0-9.-]+\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?|(https?)://[a-z0-9-]+([-.][a-z0-9]+)*\\.[a-z]+(/[^\\s]*)?(:[0-9]{1,5})?([/?].*)?$";
  let urlReg = new RegExp(regexQuery, "i");
  const spaceReg = /\s/;
  const spaceReg1 = /^\S*$/;
  let whiteSpaceReg = new RegExp(spaceReg1, "i");

  const validationSchema = Yup.object().shape(
    {
      bunch_name: Yup.string()
        .required(t("PleEntBulkName"))
        .test("bunch_name", t("SpacesNot"), (value) => {
          if (value && spaceReg.test(value)) {
            return false;
          }
          return true;
        })
        .test("bunch_name", t("SpacialNotAllow"), (value) =>
          /^[a-zA-Z0-9]+$/.test(value)
        )
        .min(2, t("BulkNameBet2To15Char"))
        .max(15, t("BulkNameBet2To15Char")),
      password: Yup.string()
        .min(8, t(ERR_CONSTS.PASSWORD_LENGTH))
        .max(10, t(ERR_CONSTS.PASSWORD_MAX_LENGTH)),
      upload: edit
        ? Yup.mixed().optional()
        : Yup.mixed().required(t("CSVFileReq")),
      expiration_link: Yup.string().when("clicks", {
        is: (clicks) => clicks && clicks !== "",
        then: () =>
          Yup.string()
            .required(t(ERR_CONSTS.EXPIRATION_LINK_REQUIRED))
            .matches(whiteSpaceReg, t(ERR_CONSTS.SPACES_NOT_ALLOWED))
            .matches(urlReg, t(ERR_CONSTS.EXPIRATION_LINK_NOT_VALID)),
        otherwise: () =>
          Yup.string()
            .matches(whiteSpaceReg, t(ERR_CONSTS.SPACES_NOT_ALLOWED))
            .url(t(ERR_CONSTS.EXPIRATION_LINK_NOT_VALID)),
      }),
      tags: Yup.array()
        .max(3, t("YouExceededMaxTagLimit"))
        .test("tags", t("TagMustBet2To12Char"), (value) =>
          value.every((tag) => tag.length >= 2 && tag.length <= 12)
        ),
      clicks: Yup.number().when("expiration_link", {
        is: (expirationLink) => expirationLink && expirationLink.length > 0,
        then: () =>
          Yup.number()
            .positive()
            .integer(t("NumberMustInt"))
            .min(1)
            .required(t(ERR_CONSTS.CLICK_REQUIRED)),
        otherwise: () =>
          Yup.number().positive().integer(t("NumberMustInt")).min(0),
      }),
      note: Yup.string()
        .trim()
        .min(2, t("NoteBetween2To50Char"))
        .max(50, t("NoteBetween2To50Char")),
    },
    [
      ["clicks", "expiration_link"],
      ["expiration_link", "clicks"],
    ]
  );
  useEffect(() => {
    if (data && !data?.error?.error) {
      formik.resetForm();
      notificationToast(t("WeViaMailBulkUse"), "success");
      refetchData();
      setTimeout(() => {
        setDisplayListCall(false);
        setDisplayList(true);
      }, 1000);
    }
    if (data && data?.error?.error) {
      notificationToast(
        t(notificationMessageFunction(data?.error?.message)),
        "error"
      );
    }
  }, [data]);
  useEffect(() => {
    if (error && error !== null) {
      notificationToast(
        t(notificationMessageFunction(error?.message)),
        "error"
      );
    }
  }, [error]);

  useEffect(() => {
    if (edited && !edited?.error?.error) {
      notificationToast(t("BulkLinkUpSuc"), "success");
      setTimeout(() => {
        formik.resetForm();
        formik.setFieldValue("bunch_name", "");
        if (location?.state?.type === "edit") {
          navigate(`/bulkLinks/bulkDetails`, {
            state: {
              id: editData?.bunch_id,
              bunch_name: formik.values?.bunch_name,
            },
          });
        } else {
          navigate(`/bulkLinks/analytics`, {
            state: {
              ...editData,
              bunch_name: formik?.values?.bunch_name,
            },
          });
        }
      }, 500);
    }
    if (edited && edited?.error?.error) {
      notificationToast(
        t(notificationMessageFunction(edited?.error?.message)),
        "error"
      );
    }
  }, [edited]);

  useEffect(() => {
    if (editError && editError !== null) {
      notificationToast(
        t(notificationMessageFunction(editError?.message)),
        "error"
      );
    }
  }, [editError]);

  const formik = useFormik({
    initialValues: {
      bunch_name: "",
      upload: "",
      domain_id: "",
      password: "",
      expire_on: "",
      status: 1,
      expiration_link: "",
      clicks: "",
      tags: [],
      note: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      onSubmit(values, action);
    },
  });
  const onSubmit = async (values, action) => {
    if (edit) {
      await editMutate({
        id: editData?.bunch_id,
        domain_id: values?.domain_id || domain,
        payload: values,
      });
    }
    if (!edit) {
      const formData = new FormData();
      formData.append("bunch_name", values.bunch_name);
      formData.append("upload", values.upload[0]);
      formData.append("domain_id", values.domain_id || domain);
      formData.append("password", values.password);
      formData.append("expire_on", values.expire_on);
      formData.append("status", values.status);
      formData.append("expiration_link", values.expiration_link);
      formData.append("clicks", values.clicks);
      formData.append("tags", values.tags);
      formData.append("note", values.note);

      await mutate({
        domain_id: values.domain_id || domain,
        payload: formData,
      });
    }
    action.setSubmitting(false);
  };

  useEffect(() => {
    if (selectedValue === "links") {
      downloadTagsCSV();
    }
  }, [selectedValue]);

  return (
    <MainCard>
      <Box pb={"16px"}>
        <>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Box pb={3}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
              >
                <Typography
                  fontSize={"16px"}
                  fontWeight={600}
                  marginRight={1}
                  marginBottom={1}
                >
                  {edit ? t("Edit") : t("Add")} {t("BulkLinks")}
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      marginRight: 1,
                      borderRadius: "48px",
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                    type="submit"
                    disabled={
                      edit ? EditIsLoading : addBulkLoading || addBulkFetching
                    }
                  >
                    {edit ? t("Update") : t("Add")}
                  </Button>
                  {!edit && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        marginLeft: 1,
                        marginRight: 1,
                        borderRadius: "48px",
                      }}
                      endIcon={
                        displayList ? <ArrowCircleRight /> : <ArrowCircleLeft />
                      }
                      onClick={() => {
                        onOffList();
                      }}
                      disabled={addBulkLoading || addBulkFetching}
                    >
                      {t("AdvancedOptions")}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
            <Divider />
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} sm={12} lg={displayList ? 12 : 5}>
                <Box>
                  <Typography sx={{ fontWeight: 500, pb: 1, pt: 1.5 }}>
                    {t("Name")}
                  </Typography>
                  <Stack spacing={1}>
                    <TextField
                      id="outlined-basic-auto"
                      placeholder={t("EnterName")}
                      autoFocus
                      fullWidth
                      InputProps={{
                        startAdornment: <DocumentText1 />,
                      }}
                      name="bunch_name"
                      className={
                        formik.errors.bunch_name ? "border-danger" : ""
                      }
                      value={formik.values.bunch_name}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      autoComplete="off"
                      error={Boolean(
                        formik.touched.bunch_name && formik.errors.bunch_name
                      )}
                    />
                    {formik.touched.bunch_name && formik.errors.bunch_name && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-bunch_name"
                      >
                        {formik.errors.bunch_name}
                      </FormHelperText>
                    )}
                  </Stack>
                  {!edit ? (
                    <>
                      <Box
                        paddingBottom={1}
                        paddingTop={3}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {t("UploadWith")}
                        </Typography>
                        <Button
                          startIcon={<Import />}
                          color="secondary"
                          sx={{
                            borderRadius: "48px",
                            paddingLeft: 3,
                            paddingRight: 3,
                            backgroundColor: "#F4F5F6",
                            color: "#000",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (selectedValue === "links") {
                              if (downloadTagsData) {
                                fileDownload(downloadTagsData, "demo.csv");
                              }
                            }
                          }}
                        >
                          {t("DownloadDemoFile")}
                        </Button>
                      </Box>
                      <Box
                        mt={1}
                        pl={1}
                        borderRadius={"8px"}
                        border={`1px solid ${
                          theme.palette.mode === ThemeMode.DARK
                            ? theme.palette.secondary[200]
                            : theme.palette.secondary[400]
                        }`}
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-download-link-label"
                          name="download-link"
                        >
                          <FormControlLabel
                            value="links"
                            control={<Radio size="small" />}
                            label={t("LinkWithAllPara")}
                            checked={selectedValue === "links"}
                            onChange={() => setSelectedValue("links")}
                          />
                        </RadioGroup>
                      </Box>
                      <Box mt={2}>
                        <SingleFileUpload
                          file={formik.values.upload}
                          setFieldValue={formik.setFieldValue}
                          name={"upload"}
                          error={
                            formik.touched.upload && !!formik.errors.upload
                          }
                          subTitle={
                            <Box textAlign={"center"}>
                              {t("DragDropFile")}
                              <br />
                              {t("OnlySingle")}
                            </Box>
                          }
                          accept={{ "text/csv": [".csv"] }}
                          isLoading={addBulkLoading || addBulkFetching}
                        />
                        {formik.touched.upload && formik.errors.upload && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-password-login"
                          >
                            {formik.errors.upload}
                          </FormHelperText>
                        )}
                      </Box>
                    </>
                  ) : (
                    <Box>
                      <img src={editImg} alt="" width={"100%"} />
                    </Box>
                  )}
                </Box>
              </Grid>
              {!displayList && (
                <>
                  <Grid
                    item
                    xs={0}
                    sm={0}
                    lg={0.5}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <Divider
                      orientation="vertical"
                      textAlign="center"
                      sx={{
                        borderStyle: "dashed",
                        borderRightWidth: "2px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={6.5}>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={2}
                      marginTop={0}
                    >
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>{t("Domain")}</InputLabel>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={t("SelDomainName")}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Global />
                                </InputAdornment>
                              }
                              disabled={isFetching}
                              name="domain_id"
                              value={formik.values.domain_id || domain}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              {domainList?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item?.domain_id}>
                                    {item?.domain}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("Password")}
                            <Tooltip
                              title={t("SecureYourShortURLPassword")}
                              arrow
                            >
                              <InfoCircle
                                size={13}
                                style={{ marginLeft: "5px" }}
                              />
                            </Tooltip>
                          </InputLabel>
                          <OutlinedInput
                            id="end-adornment-password"
                            type={showPassword ? "text" : "password"}
                            placeholder={t("EnterPassword")}
                            startAdornment={
                              <InputAdornment position="start">
                                <Lock1 />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  color="secondary"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? <Eye /> : <EyeSlash />}
                                </IconButton>
                              </InputAdornment>
                            }
                            name="password"
                            value={formik.values.password}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            autoComplete="new-password"
                            error={Boolean(
                              formik.touched.password && formik.errors.password
                            )}
                          />
                          {formik.touched.password &&
                            formik.errors.password && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-password"
                              >
                                {formik.errors.password}
                              </FormHelperText>
                            )}
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={2}
                      marginTop={0}
                    >
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("ExpirationDate")}{" "}
                            <Tooltip title={t("SetAnExpirationDate")} arrow>
                              <InfoCircle
                                size={13}
                                style={{ marginLeft: "5px" }}
                              />
                            </Tooltip>
                          </InputLabel>
                          <TextField
                            type="date"
                            placeholder={t("SelectExpDate")}
                            id="outlined-end-adornment"
                            sx={{
                              input: { cursor: "pointer" },
                              "& input::-webkit-calendar-picker-indicator": {
                                filter:
                                  theme.palette.mode === ThemeMode.DARK
                                    ? "invert(1) sepia(1) saturate(5) hue-rotate(180deg)"
                                    : "none",
                              },
                            }}
                            InputProps={{
                              startAdornment: <Calendar />,
                            }}
                            inputProps={{
                              min: !!dateData?.timezone?.value
                                ? moment()
                                    .tz(dateData?.timezone?.value)
                                    .add(1, "day")
                                    .format("YYYY-MM-DD")
                                : moment().add(1, "day").format("YYYY-MM-DD"),
                            }}
                            name="expire_on"
                            value={moment(formik.values.expire_on).format(
                              "YYYY-MM-DD"
                            )}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("LinkStatus")}
                          </InputLabel>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={t("EnterCustomCode")}
                              startAdornment={
                                <InputAdornment position="start">
                                  <Verify />
                                </InputAdornment>
                              }
                              name="status"
                              value={formik.values.status}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              <MenuItem value={1}>{t("Active")}</MenuItem>
                              <MenuItem value={0}>{t("InActive")}</MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={2}
                      marginTop={0}
                    >
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("ExpirationLink")}
                            <Tooltip title={t("UserWillRedirected")} arrow>
                              <InfoCircle
                                size={13}
                                style={{ marginLeft: "5px" }}
                              />
                            </Tooltip>
                          </InputLabel>
                          <TextField
                            placeholder={t("EntExpirationLink")}
                            id="outlined-end-adornment"
                            InputProps={{
                              startAdornment: <Paperclip2 />,
                            }}
                            name="expiration_link"
                            value={formik.values.expiration_link}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            error={
                              formik.touched.expiration_link &&
                              Boolean(formik.errors.expiration_link)
                            }
                            helperText={
                              formik.touched.expiration_link &&
                              formik.errors.expiration_link
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("ExpirationClicks")}
                            <Tooltip title={t("AddNumClickLink")} arrow>
                              <InfoCircle
                                size={13}
                                style={{ marginLeft: "5px" }}
                              />
                            </Tooltip>
                          </InputLabel>
                          <TextField
                            type="number"
                            placeholder={t("EntExpirationClick")}
                            id="outlined-end-adornment"
                            InputProps={{
                              startAdornment: <MouseCircle />,
                            }}
                            name="clicks"
                            value={formik.values.clicks}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            error={
                              formik.touched.clicks &&
                              Boolean(formik.errors.clicks)
                            }
                            helperText={
                              formik.touched.clicks && formik.errors.clicks
                            }
                            inputProps={{ min: 0 }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      rowSpacing={2}
                      columnSpacing={2}
                      marginTop={0}
                    >
                      <Grid item xs={12} sm={12} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>
                            {t("Tags")}
                            <Tooltip title={t("CategorizeYourShortURL")} arrow>
                              <InfoCircle
                                size={13}
                                style={{ marginLeft: "5px" }}
                              />
                            </Tooltip>
                          </InputLabel>
                          <Box className="linksTags ">
                            <Award className="awardIcon" />
                            <Autocomplete
                              multiple
                              freeSolo
                              value={formik.values.tags}
                              onChange={(e, tags) => {
                                formik.setFieldValue("tags", tags);
                              }}
                              options={[]}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{ input: { height: "28px" } }}
                                  placeholder={
                                    formik.values.tags.length < 3
                                      ? t("EntTags")
                                      : ""
                                  }
                                  onChange={(e) => {
                                    e.preventDefault();
                                    const inputValue = e.target.value;
                                    if (
                                      formik.values.tags.includes(inputValue)
                                    ) {
                                      formik.setFieldError(
                                        "tags",
                                        t("PleEntDiffTags")
                                      );
                                    } else {
                                      formik.setFieldError("tags", "");
                                    }
                                  }}
                                  name="tags"
                                  error={
                                    formik.errors.tags &&
                                    Boolean(formik.errors.tags)
                                  }
                                  helperText={
                                    formik.errors.tags && formik.errors.tags
                                  }
                                />
                              )}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={12} lg={6}>
                        <Stack>
                          <InputLabel sx={{ mb: 1 }}>{t("Note")}</InputLabel>
                          <OutlinedInput
                            id="end-adornment-note"
                            type={"text"}
                            placeholder={t("EnterNote")}
                            startAdornment={
                              <InputAdornment position="start">
                                <Notepad2 />
                              </InputAdornment>
                            }
                            name="note"
                            value={formik.values.note}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            error={
                              formik.touched.note && Boolean(formik.errors.note)
                            }
                          />
                          {formik?.touched?.note && formik?.errors?.note && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-password"
                            >
                              {formik?.errors?.note}
                            </FormHelperText>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </>
      </Box>
    </MainCard>
  );
};

export default BulkLinksForm;
